import animation from "./lottie/issue.json";
import Lotties from "./components/Lotties";

const Issue = () => {
  return (
    <div className="flex flex-col items-center justify-center pb-16 min-h-screen w-screen overflow-x-hidden">
      <div className="mb-10">
        <Lotties animation={animation} />
      </div>
      <div className="w-full lg:w-2/3 2xl:w-4/5 space-y-6 overflow-auto px-4 lg:px-10 2xl:px-20">
        <h2 className="text-xl sm:text-lg font-semibold text-center mb-4">
          Your order could not be placed. Please WhatsApp us at +919007742186 or
          email info@myeinste.in for assistance.
        </h2>
      </div>
    </div>
  );
};

export default Issue;

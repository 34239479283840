import { RegisterButton } from "./Register";
import doctor from "./images/doc.png";
import acad from "./images/acad.png";
import read from "./images/read.png";
import brain from "./images/brain.png";
import community from "./images/community.png";
import activity from "./images/activity.png";
import story from "./images/story.png";
import miles from "./images/miles.png";
import tips from "./images/tips.png";

const Benefits = () => {
  const benefits = [
    {
      title: "Doctor-led and Research Backed",
      icon: doctor,
    },
    {
      title: "Foundation for Academic Success",
      icon: acad,
    },
    {
      title: "Nurtures Your Little One's Genius",
      icon: read,
    },
  ];

  const features = [
    {
      title: "Masterclass on Brain Development",
      description:
        "Learn all about how brains grow with our doctor-led masterclasses. Help your child be their best.",
      icon: brain,
    },
    {
      title: "Age-specific Growth Activities",
      description:
        "Clock is ticking. Make each moment count with 4 activities, selected from our huge library, for your baby's overall growth.",
      icon: activity,
    },
    {
      title: "Developmental Milestones Tracker",
      description:
        "See how your child grows and find out if they need help. Our milestones tracker helps you stay updated with your baby's development.",
      icon: miles,
    },
    {
      title: "Stories, AudioBooks to Get Started",
      description:
        "Ignite a forever love for learning! Our collection of stories and audiobooks is designed to stimulate your baby's brain.",
      icon: story,
    },
    {
      title: "Parenting Community to Ask and Share",
      description:
        "Being a parent is a big job. Talk, share, ask and learn with other parents and doctors in our growing community.",
      icon: community,
    },
    {
      title: "5 Secrets to Raise a Genius ",
      description:
        "Want a super smart kid? Discover strategies to boost your child's genius during these critical early years of rapid growth.",
      icon: tips,
    },
  ];

  return (
    <div>
      <h2 className="text-4xl font-extrabold mb-24 md:text-[2rem] md:mb-12 text-center text-primaryDark">
        How will this workshop help?
      </h2>
      <div className="container mx-auto grid grid-cols-3 mb-24 md:mb-8 lg:grid-cols-2 md:grid-cols-1 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="px-8 py-12 rounded-lg shadow-lg bg-white flex flex-col items-center justify-center"
          >
            <div
              className={`icon-container ${feature.icon} w-14 h-14 flex flex-col items-center justify-center mb-4`}
            >
              <img src={feature.icon} alt="icon" />
            </div>
            <div>
              <h3 className="font-bold text-2xl mb-2  text-blue-800">
                {feature.title}
              </h3>
              <p className="text-gray-600 text-lg leading-relaxed">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="container mx-auto grid grid-cols-3 lg:grid-cols-1 gap-8 mb-12">
        {benefits.map((benefit, index) => (
          <div key={index} className="p-6 flex flex-col items-center">
            <div className={`icon-container ${benefit.icon} w-16 h-16 mb-4`}>
              <img src={benefit.icon} alt="icon" />
            </div>
            <h3 className="font-bold text-2xl mb-2 text-blue-900 w-3/5">
              {benefit.title}
            </h3>
          </div>
        ))}
      </div>
      <div className="mb-12 text-xs">
        NOTE: This is the introductory program. Once you are done with this, you
        can continue your baby's growth journey either on your own or with our
        subscription plans on the app.
      </div>
      <div className="ml-[-16px]">
        <RegisterButton />
      </div>
    </div>
  );
};

export default Benefits;

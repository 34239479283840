import React from "react";
import Layout from "./components/Layout";
import social from "./images/social.webp";
import head from "./images/head.webp";
import tools from "./images/tools.webp";
import miles from "./images/miles.webp";
import qanda from "./images/qanda.webp";
import { motion } from "framer-motion";
import { EnhancedRegisterButton } from "./Register";
import test1 from "./images/test1.jpg";
import test2 from "./images/test2.jpg";
import test3 from "./images/test3.jpg";
import test4 from "./images/test4.jpg";

const Testimonial = ({ image, name, review }) => {
  return (
    <div className="flex items-start m-6">
      <img src={image} alt={name} className="w-12 h-12 rounded-full mr-6" />
      <div className="flex flex-col items-start">
        <p className="text-md font-semibold">{name}</p>
        <p className="text-sm text-gray-600 italic mt-2 text-left">
          "{review}"
        </p>
      </div>
    </div>
  );
};

const Approach = () => {
  return (
    <Layout className="md:py-8 sm:py-4">
      <div>
        <h2 className="font-bold !text-[3.5rem] lg:!text-[2rem] mb-8 mt- w-full text-center text-primaryDark md:mt-20">
          Our Approach
        </h2>
      </div>
      <div className="flex flex-col items-center justify-between w-full h-auto bg-slate-100 rounded-xl pt-[10vw] pb-10 mb-6 overflow-hidden">
        <div className="text-center  w-2/3 mb-12">
          <h3 className="text-xl xs:text-lg text-slate-500 font-semibold">
            Parenting Village
          </h3>
          <p className="text-4xl lg:text-3xl md:text-2xl xs:text-xl font-semibold mt-4 xs:mx-2">
            {
              "Share your memories, ask questions and get answers from other parents and experts."
            }
          </p>
        </div>
        <motion.div
          className="w-1/2 md:w-full flex justify-center"
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring" }}
        >
          <img
            src={social}
            className="w-66 md:w-60 xs:w-40 h-70 md:h-auto object-contain"
            alt="A screenshot of our app showing various questions and answers about baby's growth."
            priority
          />
        </motion.div>
      </div>
      <div className="flex flex-row md:flex-col items-start md:items-center self-end justify-between w-full h-auto bg-slate-100 rounded-xl pt-[10vw] px-[4vw] mb-6 overflow-hidden">
        <div className="text-center w-[60%] xs:w-full mt-0 md:mb-8 xs:mx-2">
          <h3 className="text-xl xs:text-lg text-slate-500 font-semibold">
            Personalization
          </h3>
          <p className="text-4xl lg:text-3xl md:text-2xl xs:text-xl font-semibold mt-4 xs:mx-2">
            {"Every baby is different. So is every baby's development plan."}
          </p>
        </div>
        <motion.div
          className="w-1/3 md:w-full flex justify-center"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 2, type: "spring" }}
        >
          <img
            src={head}
            className="w-60 md:w-50 xs:w-40 h-70 md:h-auto object-contain"
            alt="A phone showing the first screen of our myEinste.in app."
            priority
          />
        </motion.div>
      </div>
      <div className="flex flex-row items-start md:items-center self-end md:flex-col-reverse justify-between w-full h-auto bg-slate-100 rounded-xl pt-[10vw] mb-6 overflow-hidden">
        <motion.div
          className="w-1/2 md:w-full flex justify-start"
          initial={{ opacity: 0, skewX: 10 }}
          whileInView={{ opacity: 1, skewX: 0 }}
          transition={{ duration: 2, type: "spring" }}
        >
          <img
            src={tools}
            className="w-70 md:w-full xs:w-60 h-70 md:h-auto object-contain"
            alt="Three phones showing app screens for baby development tools."
            priority
          />
        </motion.div>
        <div className="text-center pr-[10vw] md:pr-0 md:pt-0 2xl:mt-12 w-1/2 xs:w-full md:mt-0 xs:mx-2">
          <h3 className="text-xl xs:text-lg text-slate-500 font-semibold">
            Growth Aids
          </h3>
          <p className="text-4xl lg:text-3xl md:text-2xl xs:text-xl font-semibold mt-4 xs:mx-2">
            {"Library of short stories, poems, audio-books. And lots more."}
          </p>
        </div>
      </div>
      <div className="flex flex-row  items-start md:items-center md:flex-col-reverse justify-between w-full h-auto bg-slate-100 rounded-xl pt-[10vw] mb-6  overflow-hidden">
        <motion.div
          className="w-1/2 md:w-full flex justify-start"
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring" }}
        >
          <img
            src={miles}
            className="w-70 md:w-82 xs:w-64 h-70 md:h-auto object-contain"
            alt="A phone showing app screen with development milestones of a baby."
            priority
          />
        </motion.div>
        <div className="w-1/2 xs:w-full text-center lg:mt-0 2xl:mt-12 md:pr-0 2xl:pr-12">
          <h3 className="text-xl xs:text-lg text-slate-500 font-semibold">
            Tracking
          </h3>
          <p className="text-4xl lg:text-3xl md:text-2xl xs:text-xl font-semibold mt-4 xs:mx-2">
            {"Monitor your baby's milestones through auto tracking."}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between w-full h-auto bg-slate-100 rounded-xl pt-[10vw] pb-10 md:pb-0 overflow-hidden">
        <div className="text-center  w-2/3 mb-16">
          <h3 className="text-xl xs:text-lg text-slate-500 font-semibold">
            Ask The Experts
          </h3>
          <p className="text-4xl lg:text-3xl md:text-2xl xs:text-xl font-semibold mt-4 xs:mx-2">
            {"We know you have a lot of questions. We have answers."}
          </p>
        </div>
        <motion.div
          className="w-1/2 md:w-full flex justify-center"
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring" }}
        >
          <img
            src={qanda}
            className="w-66 md:w-60 xs:w-50 h-70 md:h-auto object-contain"
            alt="A screenshot of our app showing various questions and answers about baby's growth."
            priority
          />
        </motion.div>
      </div>
      <div className="md:w-full lg:w-3/4 w-2/3 mx-auto h-auto mt-16">
        <Testimonial
          image={test1}
          name="Srivali Krishnan"
          review="My baby started crawling at just 4 months! Coz of this workshop, we feel prepared for every surprise."
        />
        <Testimonial
          image={test3}
          name="Vijay Kumble"
          review="Jiya began babbling and trying to communicate so early, we were amazed! Thanks a lot."
        />
        <Testimonial
          image={test2}
          name="Minakshi Sharma"
          review="As first-time parents, I was anxious about my baby's development. This helped us making sense of the science behind it all."
        />
        <Testimonial
          image={test4} // New image path for the new testimonial
          name="Ramesh Nair"
          review="This workshop demystified early childhood development for us. Now, we approach parenting with more confidence and knowledge."
        />
      </div>
      <div className="mb-12">
        <EnhancedRegisterButton />
      </div>
    </Layout>
  );
};

export default Approach;

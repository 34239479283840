import "./App.css";
import { useEffect, useRef, useState } from "react";
import Layout from "./components/Layout";
import Experience from "./components/Experience";
import Benefits from "./Benefits";
import CountdownTimer from "./CoutdownTimer";
import StickyHeader from "./StickyHeader";
import Hero from "./Hero";
import Approach from "./Approach";
import Backed from "./Backed";
import Footer from "./Footer";
import * as amplitude from "@amplitude/analytics-browser";
import Lottie from "lottie-react";
import reach from "./lottie/reached.json";
import sticky from "./images/sticky.webp";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import PDFModal from "./components/PdfModal";

amplitude.init("2cfa350f9e9b60940fae5d45bee337e0");

const LottieView = () => {
  return (
    <div className="w-1/3 sm:w-1/4 h-auto mt-1">
      <Lottie animationData={reach} loop={true} className="ml-2 h-auto" />
    </div>
  );
};

const StickyBonus = ({ onClick }) => {
  const handleClick = () => {
    amplitude.track("Offer Clicked");
    onClick(true);
  };

  return (
    <button
      className="fixed top-12 lg:top-8 md:top-6 left-0 w-full h-24 shadow-lg z-40 bg-white border-2"
      onClick={handleClick}
    >
      <div className="flex items-center justify-center w-full">
        <img src={sticky} className="w-1/3 h-20 sm:w-4/5 top-0 mt-2" />
      </div>
    </button>
  );
};

function MainPage() {
  const [reached, setReached] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const heroRef = useRef(null);
  const countdownTimerRef = useRef(null);
  const backedRef = useRef(null);
  const benefitsRef = useRef(null);
  const experienceRef = useRef(null);
  const approachRef = useRef(null);

  const getReached = async () => {
    try {
      const workshopRef = await getDoc(doc(db, "agreements", "acquisition"));
      setReached(() => workshopRef.data().reached);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const sections = [
      { ref: heroRef, name: "Hero" },
      { ref: countdownTimerRef, name: "CountdownTimer" },
      { ref: backedRef, name: "Backed" },
      { ref: benefitsRef, name: "Benefits" },
      { ref: experienceRef, name: "Experience" },
      { ref: approachRef, name: "Approach" },
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const section = sections.find(
              (s) => s.ref.current === entry.target
            );
            amplitude.track(`${section.name} Scrolled`);
            window.fbq("trackCustom", `${section.name} Scrolled`);
          }
        });
      },
      { threshold: 0.1 }
    );

    sections.forEach((section) => {
      if (section.ref.current) {
        observer.observe(section.ref.current);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section.ref.current) {
          observer.unobserve(section.ref.current);
        }
      });
    };
  }, []);

  useEffect(() => {
    getReached();
    amplitude.track("Landing View");
  }, []);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="App">
      <main className="font-sans w-full bg-light overflow-x-hidden">
        <StickyHeader />
        <StickyBonus onClick={handleClick} />
        <div className="w-1/6 sm:w-2/5 md:w-1/5 h-1/12 sm:h-1/10 md:h-1/8 fixed z-50 bottom-4 right-4 bg-white text-black p-1 rounded-lg shadow-lg flex items-center">
          <LottieView animation={reached} />
          <p className="w-3/4 sm:w-2/3 text-xs m-2">
            <strong>{reached} </strong>ENROLLED THIS MONTH
          </p>
        </div>
        {isModalOpen && <PDFModal onClose={() => setIsModalOpen(false)} />}
        <section
          className="w-full h-auto bg-primaryDark relative"
          ref={heroRef}
        >
          <Hero />
        </section>
        <section ref={countdownTimerRef}>
          <CountdownTimer />
        </section>
        <section className="w-screens h-auto bg-light" ref={backedRef}>
          <Backed />
        </section>
        <section
          className="w-full h-auto bg-primaryDark overflow-hidden "
          ref={experienceRef}
        >
          <Layout>
            <Experience />
          </Layout>
        </section>
        <section
          className="bg-gradient-to-r from-purple-200 via-blue-100 to-white py-12 "
          ref={benefitsRef}
        >
          <Layout>
            <Benefits />
          </Layout>
        </section>
        <section className="w-full h-auto" ref={approachRef}>
          <Approach />
        </section>
      </main>
      <footer className="w-full pb-12 md:pb-0 bg-primaryDark md:mt-0 mt-2">
        <section className="w-full h-full font-medium text-lg">
          <Footer />
        </section>
      </footer>
    </div>
  );
}
export default MainPage;

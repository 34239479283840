import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./MainPage";
import Congrats from "./Congrats";
import Cancelled from "./Cancelled";
import Payment from "./Payment";
import Processing from "./Processing";
import Manual from "./Manual";
import Webinar from "./Webinar";
import Booked from "./Booked";
import Ignite from "./Ignite";
import IgniteProcessing from "./IgniteProcessing";
import Accelerate from "./Accelerate";
import AccelerateProcessing from "./AccelerateProcessing";
import Elite from "./Elite";
import Confirmed from "./Confirmed";
import Issue from "./Issue";
import EliteProcessing from "./EliteProcessing";
import IgniteManual from "./IgniteManual";
import AccelerateManual from "./AccelerateManual";
import EliteManual from "./EliteManual";
import FoodPayment from "./FoodPayment";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/congrats" element={<Congrats />} />
        <Route path="/links" element={<Congrats />} />
        <Route path="/confirmed" element={<Confirmed />} />
        <Route path="/cancelled" element={<Cancelled />} />
        <Route path="/issue" element={<Issue />} />
        <Route path="/payment" element={<Payment key={Date.now()} />} />
        <Route path="/processing" element={<Processing />} />
        <Route path="/ignite_processing" element={<IgniteProcessing />} />
        <Route
          path="/accelerate_processing"
          element={<AccelerateProcessing />}
        />
        <Route path="/elite_processing" element={<EliteProcessing />} />
        <Route path="/ignite_manual" element={<IgniteManual />} />
        <Route path="/accelerate_manual" element={<AccelerateManual />} />
        <Route path="/elite_manual" element={<EliteManual />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/manual" element={<Manual />} />
        <Route path="/booked" element={<Booked />} />
        <Route path="/ignite" element={<Ignite />} />
        <Route path="/elite" element={<Elite />} />
        <Route path="/accelerate" element={<Accelerate />} />
        <Route path="/food" element={<FoodPayment />} />
      </Routes>
    </Router>
  );
}

export default App;

import { useState, useEffect } from "react";
import backgroundImage from "./images/181.jpg";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import logo from "./images/logo.webp";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "./firebase";
import * as amplitude from "@amplitude/analytics-browser";

amplitude.init("2cfa350f9e9b60940fae5d45bee337e0");

const Hero = () => {
  return (
    <section
      className="relative text-blue-900 text-left py-20 flex items-center justify-left flex-col"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundBlendMode: "overlay",
        backgroundColor: "rgba(86, 248, 253, 0.8)", // This color will overlay the background image
      }}
    >
      <img
        src={logo}
        alt="Logo of myEinstein"
        className={
          "w-[200px] h-auto absolute lg:left-[50vw] lg:-translate-x-1/2 top-10 "
        }
      />
      <div className="container mx-auto text-center relative z-10">
        <h1 className="text-5xl md:text-4xl font-extrabold my-12 ">
          The Booster Method
        </h1>
        <p className="text-lg md:text-lg max-w-2xl mx-auto bg-white text-black py-2 px-4 rounded">
          <span className="font-bold">Congratulations!</span> You are invited to
          attend the myEinstein Booster Method Webinar for FREE!!!
        </p>
      </div>
      <RegistrationForm />
    </section>
  );
};

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    whatsappNumber: "",
    schedule: "",
    scheduleId: "", // Add this line
  });
  const [schedules, setSchedules] = useState([]);
  const [webinarId, setWebinarId] = useState("");
  const [register, setRegister] = useState(false);

  useEffect(() => {
    axios
      .post(
        "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/getWebinars"
      )
      .then((response) => {
        if (response.data.status === "success") {
          setSchedules(
            response.data.webinars[0].schedules.filter((schedule) => {
              const date = new Date(schedule);
              return date > Date.now();
            })
          );
          setWebinarId(response.data.webinars[0].webinar_id);
        }
      })
      .catch((error) => console.error("Error fetching webinars:", error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "schedule") {
      const selectedSchedule = schedules.find((s) => s.comment === value);
      setFormData((prev) => ({
        ...prev,
        schedule: value,
        scheduleId: selectedSchedule,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setRegister(true);
    const fullPhoneNumber = formData.whatsappNumber;
    fetch(
      "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/getWebinarDetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webinarId: webinarId,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Webinar details fetched:", data);
        const selectedSchedule = data.webinar.schedules.find(
          (schedule) => schedule.comment === formData.schedule
        );

        if (!selectedSchedule) {
          console.error("Selected schedule not found");
          return;
        }

        const scheduleId = selectedSchedule.schedule;
        return fetch(
          "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/registerUser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              firstName: formData.fullName,
              email: formData.email,
              webinarId: webinarId,
              schedule: scheduleId,
              phoneNumber: fullPhoneNumber.slice(3),
              countryCode: fullPhoneNumber.slice(0, 3),
            }),
          }
        );
      })
      .then((response) => response.json())
      .then((data) => {
        console.log("User registered successfully:", data);
        const userEmail = formData.email;
        const selectedSchedule = formData.schedule;
        const url = data.user.live_room_url;
        const extractedPart = url.split("https://event.webinarjam.com/")[1];
        const sendWhatsAppTemplateMessage = async (templateData) => {
          const WATI_API_ENDPOINT = `https://live-mt-server.wati.io/301364/api/v1/sendTemplateMessage?whatsappNumber=${String(
            Number(fullPhoneNumber)
          )}`;
          const token =
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOWU4YTBhZS0xYjUzLTQwOWEtOWU1OS0yNmZiYmI3ZDZiN2QiLCJ1bmlxdWVfbmFtZSI6ImluZm9AbXllaW5zdGUuaW4iLCJuYW1laWQiOiJpbmZvQG15ZWluc3RlLmluIiwiZW1haWwiOiJpbmZvQG15ZWluc3RlLmluIiwiYXV0aF90aW1lIjoiMTIvMDQvMjAyMyAwNzo1Njo1MSIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDEzNjQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.KNhckSDK9_b7eoLjNzYEKhLczabrp4zoccyIcK04uhI";
          const requestOptions = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(templateData),
          };

          try {
            const response = await fetch(WATI_API_ENDPOINT, requestOptions);
            const data = await response.json();
            return data;
          } catch (error) {
            console.error("Error sending WhatsApp template message:", error);
          }
        };

        const handleSendHotMessage = () => {
          const templateData = {
            broadcast_name: "register",
            parameters: [
              { name: "name", value: data.user.first_name },
              { name: "register", value: Date.now() },
              {
                name: "time",
                value: new Date(data.user.date).toLocaleString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: data.user.timezone,
                }),
              },
              { name: "link", value: extractedPart },
              { name: "fullname", value: data.user.first_name },
            ],
            template_name: "register",
          };
          sendWhatsAppTemplateMessage(templateData).then((data) => {
            console.log(data);
          });
          console.log("time", Date.parse(data.user.date));
          amplitude.track(
            "Link Sent",
            {
              email: userEmail,
              phone: fullPhoneNumber,
            },
            { user_id: userEmail }
          );
        };
        updateDoc(doc(db, "users", userEmail), {
          booked: selectedSchedule,
          parentName: formData.fullName,
          replay: data.user.replay_room_url,
        })
          .then(() => {
            console.log("Firestore document updated with booked schedule.");
            const webinarDocRef = doc(db, "webinars", selectedSchedule);

            getDoc(webinarDocRef).then((docSnapshot) => {
              if (docSnapshot.exists()) {
                return updateDoc(webinarDocRef, {
                  participants: arrayUnion({
                    name: formData.fullName,
                    phoneNumber: String(Number(fullPhoneNumber)),
                    link: extractedPart,
                    reminderSent: false,
                    email: formData.email,
                  }),
                });
              } else {
                return setDoc(webinarDocRef, {
                  time: Date.parse(data.user.date),
                  timeStr: new Date(data.user.date).toLocaleString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    timeZone: data.user.timezone,
                  }),
                  participants: [
                    {
                      name: formData.fullName,
                      phoneNumber: String(Number(fullPhoneNumber)),
                      link: extractedPart,
                      reminderSent: false,
                      email: formData.email,
                    },
                  ],
                });
              }
            });
            navigate("/booked");
          })
          .catch((error) => {
            console.error("Error updating Firestore document:", error);
          });
        amplitude.track(
          "Webinar Registered",
          {
            email: userEmail,
            schedule: selectedSchedule,
          },
          { user_id: userEmail }
        );

        return handleSendHotMessage();
      })
      .then((response) => response.text())
      .then((data) => {
        console.log("WhatsApp message sent:", data);
        navigate("/booked");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <section className="py-16 px-2 text-white">
      <div className="container mx-auto text-center">
        <form
          onSubmit={handleSubmit}
          className="max-w-lg mx-auto p-8 rounded-lg shadow-lg bg-opacity-90 bg-white/60 backdrop-blur-md text-black"
        >
          <h2 className="text-2xl font-bold mb-8">Book your seat</h2>
          <div className="mb-8">
            <label className="block mb-4 text-black" htmlFor="fullName">
              Name
            </label>
            <input
              type="text"
              id="fullName"
              placeholder="Enter your full name"
              name="fullName"
              className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="mb-8">
            <label className="block mb-4 text-black" htmlFor="email">
              Email ID
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email address"
              name="email"
              className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="mb-8">
            <label className="block mb-4 text-black" htmlFor="whatsappNumber">
              WhatsApp Number
            </label>
            <PhoneInput
              id="whatsappNumber"
              name="whatsappNumber"
              placeholder="Enter phone number"
              defaultCountry="IN"
              value={formData.whatsappNumber}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, whatsappNumber: value }))
              }
              required
              className="w-full border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none px-4 py-2 rounded-lg bg-white"
            />
          </div>

          <div className="mb-6">
            <label className="block mb-2 text-black" htmlFor="schedule">
              Select Date & Time
            </label>
            <select
              id="schedule"
              name="schedule"
              className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none text-black"
              onChange={handleInputChange}
              required
            >
              <option value="">--Select--</option>
              {schedules.map((schedule, index) => (
                <option key={index} value={schedule}>
                  {schedule}
                </option>
              ))}
            </select>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-bold px-4 py-2 my-3 rounded-lg shadow-lg hover:shadow-xl hover:bg-light-blue-500 hover:text-white focus:outline-none focus:ring-4 focus:ring-light-blue-500 focus:ring-opacity-50 transition-all duration-300"
          >
            {register ? "Registering..." : "Register Now"}
          </button>
        </form>
      </div>
    </section>
  );
};

const Webinar = () => {
  return (
    <div>
      <Hero />
    </div>
  );
};

export default Webinar;

import React, { useState, useEffect } from "react";

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const deadlineDate = new Date();
  deadlineDate.setDate(deadlineDate.getDate() + 1);

  const deadlineString = deadlineDate.toLocaleString("en-US", {
    weekday: "long",
    day: "numeric",
    month: "long",
  });

  function calculateTimeLeft() {
    const now = new Date();
    const deadline = new Date(now);
    deadline.setHours(0, 0, 0, 0);
    deadline.setDate(deadline.getDate() + 1);
    deadline.setHours(24);
    const difference = deadline - now;
    return {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-white  px-12 py-8 mt-4 text-center shadow-2xl ">
      <h2 className="text-3xl md:text-xl font-extrabold mb-4 text-primaryDark tracking-wider uppercase animate__animated animate__bounce">
        Registration Closes
      </h2>
      <p className="text-lg font-extrabold mb-6 text-gray-500">
        {deadlineString}
      </p>
      <div className="grid grid-cols-3 bg-green-400 shadow-lg rounded-3xl mx-48 md:mx-0 py-3 text-white text-4xl md:text-2xl font-extrabold relative">
        <div className="flex flex-col items-center space-y-2 relative">
          <span>{String(timeLeft.hours).padStart(2, "0")}</span>
          <span className="text-xl lg:text-sm text-green-200 tracking-wider">
            HOURS
          </span>
          <div className="absolute right-0 w-[2px] h-4/5 bg-green-200 rounded-full self-center"></div>
        </div>
        <div className="flex flex-col  items-center space-y-2 relative">
          <span>{String(timeLeft.minutes).padStart(2, "0")}</span>
          <span className="text-xl lg:text-sm text-green-200 tracking-wider">
            MINUTES
          </span>
          <div className="absolute right-0 w-[2px] h-4/5 bg-green-200 rounded-full self-center"></div>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <span>{String(timeLeft.seconds).padStart(2, "0")}</span>
          <span className="text-xl lg:text-sm text-green-200 tracking-wider">
            SECONDS
          </span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;

import animation from "./lottie/confirmed.json";
import apple from "./images/apple.png";
import app from "./images/play.png";
import Lotties from "./components/Lotties";
import Footer from "./Footer";

const Confirmed = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const awb = urlParams.get("awb");
  const handleAndroid = () => {
    const appUrl =
      "https://play.google.com/store/apps/details?id=com.abkhansari.Mountford";
    window.open(appUrl, "_blank");
  };
  const handleApple = () => {
    const appUrl = "https://apps.apple.com/in/app/myeinstein/id6446261006";
    window.open(appUrl, "_blank");
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center pb-40 min-h-screen bg-gradient-to-r from-green-400 to-blue-600 text-white w-full overflow-x-hidden max-w-screen">
        <div className="mb-10">
          <Lotties animation={animation} />
        </div>

        <div className="w-full lg:w-[90vw] 2xl:w-3/5 space-y-6 overflow-auto px-4 lg:px-10 2xl:px-20 mb-14">
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-4">
            Your Order is confirmed!
          </h2>

          <div className="flex flex-col space-y-6 mb-10">
            <div className="flex flex-col space-y-4">
              <div className="flex items-start gap-2">
                <span className="text-4xl 2xl:text-7xl">1</span>
                <div className="flex flex-col space-y-2">
                  <h3 className="font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl">
                    Download
                  </h3>
                  <p className="text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl">
                    Click the link below to download the myEinstein app if you
                    have'nt already.
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center space-y-4">
                <button onClick={handleAndroid}>
                  <img
                    src={app}
                    alt="Play Store"
                    className="w-56 h-20 object-contain mt-5"
                  />
                </button>
                <button onClick={handleApple}>
                  <img
                    src={apple}
                    alt="App Store"
                    className="w-56 h-20 object-contain mt-2 "
                  />
                </button>
              </div>
            </div>

            <div className="flex items-start gap-2">
              <span className="text-4xl 2xl:text-7xl">2</span>
              <div className="flex flex-col space-y-2">
                <h3 className="font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl">
                  Prepare
                </h3>
                <p className="text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl">
                  Watch the guidelines under the Elite Membership section while
                  your order is on the way.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-2">
              <span className="text-4xl 2xl:text-7xl">3</span>
              <div className="flex flex-col space-y-2">
                <h3 className="font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl">
                  Get Set Go!
                </h3>
                <p className="text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl">
                  Start your baby's journey as soon as the Kit reaches your
                  doorstep. Click the link below to track your order.
                </p>
                <a
                  href={"https://myeinstein.shiprocket.co/tracking/" + awb}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300 my-4">
                    Track Order
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Confirmed;

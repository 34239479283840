import Lottie from "lottie-react";

const LottieInView = ({ animation }) => {
  console.log(animation);
  return (
    <div className={"w-full h-auto ml-20 md:ml-32 mt-32 md:mt-16 sm:ml-20"}>
      {animation.v === "5.9.0" ? (
        <Lottie
          animationData={animation}
          loop={true}
          className={`w-[54%] ml-2 h-auto`}
        />
      ) : (
        <Lottie
          animationData={animation}
          loop={true}
          className={`w-[60%] h-auto`}
        />
      )}
    </div>
  );
};

export default LottieInView;

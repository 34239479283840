import Lottie from "lottie-react";

const Lotties = ({ animation }) => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-auto  mt-20 ">
      {
        <Lottie
          animationData={animation}
          loop={true}
          className={`w-[40%] h-auto`}
        />
      }
    </div>
  );
};

export default Lotties;

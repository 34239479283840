import React from "react";

function PdfModal({ onClose }) {
  const images = Array.from({ length: 27 }, (_, index) => `/${index}.jpg`);

  const handleOutsideClick = (event) => {
    onClose();
  };

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={handleOutsideClick}
    >
      <div
        className="bg-white p-6 rounded-lg relative w-11/12 md:w-4/5 h-3/4 md:h-3/4 overflow-y-auto"
        onClick={handleModalClick}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded z-10"
        >
          Close
        </button>
        {images.map((image, index) => (
          <div key={index} className="mb-4">
            <img
              src={image}
              alt={`Page ${index + 1}`}
              className="w-full object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PdfModal;

import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import payment from "./images/payment.webp";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, firebaseApp } from "./firebase";
import * as amplitude from "@amplitude/analytics-browser";

amplitude.init("2cfa350f9e9b60940fae5d45bee337e0");

const FoodPayment = () => {
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(59900);
  const functions = getFunctions(firebaseApp, "asia-south1");
  const makePayments = httpsCallable(functions, "makePayments");

  const getPrice = async () => {
    const workshopRef = await getDoc(doc(db, "agreements", "acquisition"));

    setPrice(() => Number(workshopRef.data().foodprice) * 100);
  };

  useEffect(() => {
    return () => setIsLoading(false); // Reset on unmount
  }, []);

  useEffect(() => {
    getPrice();
    amplitude.track("Payment Page");
    window.fbq("trackCustom", "Payment Page");
  }, []);

  const addContact = async (templateData) => {
    const WATI_API_ENDPOINT = `https://live-mt-server.wati.io/301364/api/v1/addContact/whatsappNumber=${String(
      Number(phone)
    )}`;
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOWU4YTBhZS0xYjUzLTQwOWEtOWU1OS0yNmZiYmI3ZDZiN2QiLCJ1bmlxdWVfbmFtZSI6ImluZm9AbXllaW5zdGUuaW4iLCJuYW1laWQiOiJpbmZvQG15ZWluc3RlLmluIiwiZW1haWwiOiJpbmZvQG15ZWluc3RlLmluIiwiYXV0aF90aW1lIjoiMTIvMDQvMjAyMyAwNzo1Njo1MSIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDEzNjQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.KNhckSDK9_b7eoLjNzYEKhLczabrp4zoccyIcK04uhI";
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(templateData),
    };

    try {
      amplitude.track("Contact Added", {
        email: email.toLowerCase(),
        phone: phone,
      });
      const response = await fetch(WATI_API_ENDPOINT, requestOptions);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error adding contact:", error);
    }
  };

  const handleAddContact = () => {
    const templateData = {
      customParams: [
        {
          name: "name",
          value: email.toLowerCase(),
        },
        { name: "warm", value: Date.now() },
      ],
    };
    addContact(templateData)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePay = async (e) => {
    e.preventDefault();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!phone || phone.length > 13) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }
    setIsLoading(true);
    const merchantTransactionId = phone.slice(5) + String(Date.now());
    const timestamp = Date.now();
    makePayments({
      merchantId: "M1TBTIGYFCW5",
      merchantTransactionId: merchantTransactionId,
      merchantUserId: phone,
      amount: price,
      redirectUrl: `https://www.myeinste.in/processing?email=${email.toLowerCase()}&phone=${phone}&merchantTransactionId=${merchantTransactionId}`,
      redirectMode: "POST",
      callbackUrl:
        "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/uiCallbacks",
      mobileNumber: phone,
      paymentInstrument: { type: "PAY_PAGE" },
    })
      .then((result) => {
        console.log("result", result.data);
        if (result.data.instrumentResponse.redirectInfo.url) {
          window.location.href =
            result.data.instrumentResponse.redirectInfo.url;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg max-w-md">
        <div className="text-center text-2xl font-bold mb-6">Registration</div>
        <div className="mb-3 flex flex-col items-center justify-center rounded-lg overflow-hidden shadow-lg">
          <img src={payment} alt="A mother reading to her baby." />
        </div>
        <div className="text-xs text-gray-400 italic mb-8 text-center">
          You will be granted access to the Food database on our app within 15
          minutes of receiving the payment. The access will be for 36 months
          starting today.
        </div>
        <form onSubmit={handlePay}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Enter email id"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
            <div className="text-xs text-gray-400 italic mt-1">
              This email id will be required by you to login.
            </div>
          </div>
          <div className="mb-4 ">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              WhatsApp Number
            </label>
            <div className="flex">
              <PhoneInput
                placeholder="Enter phone no."
                value={phone}
                onChange={setPhone}
                limitMaxLength={true}
                defaultCountry="IN"
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "0.5rem 0.75rem",
                  fontSize: "1rem",
                  lineHeight: "1.5",
                  color: "#495057",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #ced4da",
                  borderRadius: "0.25rem",
                }}
              />
            </div>
            <div className="text-xs text-gray-400 italic mt-1"></div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-2 mt-4 rounded-md hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 active:bg-blue-700 transition duration-150 ease-in-out"
          >
            {isLoading ? "Loading..." : "Proceed to Payment"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FoodPayment;

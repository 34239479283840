import animation from "./lottie/congrats.json";
import apple from "./images/apple.png";
import app from "./images/play.png";
import Lotties from "./components/Lotties";
import { useEffect } from "react";

const Congrats = () => {
  const handleAndroid = () => {
    const appUrl =
      "https://play.google.com/store/apps/details?id=com.abkhansari.Mountford";
    window.open(appUrl, "_blank");
  };
  const handleApple = () => {
    const appUrl = "https://apps.apple.com/in/app/myeinstein/id6446261006";
    window.open(appUrl, "_blank");
  };
  return (
    <div className="flex flex-col items-center justify-center pb-40 min-h-screen bg-gradient-to-r from-pink-400 to-blue-600 text-white w-full overflow-x-hidden max-w-screen">
      <div className="mb-10">
        <Lotties animation={animation} />
      </div>

      <div className="w-full lg:w-[90vw] 2xl:w-3/5 space-y-6 overflow-auto px-4 lg:px-10 2xl:px-20">
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-4">
          Congratulations! Here are the next steps:
        </h2>

        <div className="flex flex-col space-y-6 mb-10">
          <div className="flex flex-col space-y-4">
            <div className="flex items-start gap-2">
              <span className="text-4xl 2xl:text-7xl">1</span>
              <div className="flex flex-col space-y-2">
                <h3 className="font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl">
                  Download
                </h3>
                <p className="text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl">
                  Click the link below to download the myEinstein app.
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center space-y-4">
              <button onClick={handleAndroid}>
                <img
                  src={app}
                  alt="Play Store"
                  className="w-56 h-20 object-contain mt-5"
                />
              </button>
              <button onClick={handleApple}>
                <img
                  src={apple}
                  alt="App Store"
                  className="w-56 h-20 object-contain mt-2 "
                />
              </button>
            </div>
          </div>

          <div className="flex items-start gap-2">
            <span className="text-4xl 2xl:text-7xl">2</span>
            <div className="flex flex-col space-y-2">
              <h3 className="font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl">
                Register
              </h3>
              <p className="text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl">
                With your email id you used to make the payment and a password
                of your choice.
              </p>
            </div>
          </div>

          <div className="flex items-start gap-2">
            <span className="text-4xl 2xl:text-7xl">3</span>
            <div className="flex flex-col space-y-2">
              <h3 className="font-semibold text-lg sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl">
                Get Set Go!
              </h3>
              <p className="text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl">
                'myEinstein Growth Workshop' will now be available on the app
                and you can access it once your session begins
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congrats;

import React from "react";
import { FaFacebookF, FaInstagram, FaMailBulk } from "react-icons/fa";
import Layout from "./components/Layout";

const Footer = () => {
  const handleAndroid = () => {
    const appUrl =
      "https://play.google.com/store/apps/details?id=com.abkhansari.Mountford";
    window.open(appUrl, "_blank");
  };
  const handleApple = () => {
    const appUrl = "https://apps.apple.com/in/app/myeinstein/id6446261006";
    window.open(appUrl, "_blank");
  };

  const handlePrivacy = () => {
    const fileURL = "/privacy.html";
    window.open(fileURL, "_blank");
  };

  const handleRefund = () => {
    const fileURL = "/refund.html";
    window.open(fileURL, "_blank");
  };

  const handleTerms = () => {
    const fileURL = "/terms.html";
    window.open(fileURL, "_blank");
  };

  const handleShipping = () => {
    const fileURL = "/shipping.html";
    window.open(fileURL, "_blank");
  };

  const handleDeletion = () => {
    const fileURL = "/deletion.html";
    window.open(fileURL, "_blank");
  };

  const handleContact = () => {
    const fileURL = "/contact.html";
    window.open(fileURL, "_blank");
  };
  return (
    <Layout className="p-2 pb-0 flex flex-col items-center md:justify-end ">
      <div className="text-[0.6rem] flex flex-col justify-center items-center  text-white mb-10">
        <p>Copyright &copy; Wystan Health Private Limited</p>
        <p>All Rights Reserved</p>
      </div>
      <div className="flex flex-row w-1/5 md:w-1/3 sm:w-1/2 items-center h-auto justify-between mb-6">
        <div className="flex items-center">
          <a href={"mailto:info@myeinste.in"}>
            <FaMailBulk className="hover:text-primary text-light text-2xl px-1" />
          </a>
        </div>
        <div className="flex items-center">
          <a
            href={"https://www.facebook.com/profile.php?id=100090722617659"}
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF className="hover:text-primary text-light text-xl px-1" />
          </a>
        </div>
        <div className="flex items-center">
          <a
            href={"https://www.instagram.com/myeinstein.in?igshid=YmMyMTA2M2Y="}
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="hover:text-primary text-light text-2xl px-1" />
          </a>
        </div>
      </div>
      {/* <div>
        <button onClick={handleApple} className="text-light text-xs">
          iOS
        </button>
        <span>{"   "}</span>
        <button onClick={handleAndroid} className="text-light text-xs">
          Android
        </button>
      </div> */}
      <div>
        <button onClick={handlePrivacy} className="text-light text-xs">
          Privacy Policy
        </button>
        <span>{"      "}</span>
        <button onClick={handleTerms} className="text-light text-xs">
             Terms and Conditions
        </button>
      </div>
      <div>
        <button onClick={handleRefund} className="text-light text-xs">
          Refunds/Cancellations
        </button>
        <span>{"      "}</span>
        <button onClick={handleShipping} className="text-light text-xs">
          Pricing & Shipping Policy
        </button>
      </div>
      <div>
        <button onClick={handleDeletion} className="text-light text-xs">
          Request Data Deletion
        </button>
        <span>{"      "}</span>
        <button onClick={handleContact} className="text-light text-xs">
          Contact Us
        </button>
      </div>
    </Layout>
  );
};

export default Footer;

import animation from "./lottie/processing.json";
import Lotties from "./components/Lotties";
import { useEffect, useState } from "react";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import * as amplitude from "@amplitude/analytics-browser";

amplitude.init("2cfa350f9e9b60940fae5d45bee337e0");

const Processing = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const merchantTransactionId = urlParams.get("merchantTransactionId");
  const email = urlParams.get("email");
  const phoneNumber = Number(urlParams.get("phone"));

  const sendWhatsAppTemplateMessage = async (templateData) => {
    const WATI_API_ENDPOINT = `https://live-mt-server.wati.io/301364/api/v1/sendTemplateMessage?whatsappNumber=${String(
      phoneNumber
    )}`;
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOWU4YTBhZS0xYjUzLTQwOWEtOWU1OS0yNmZiYmI3ZDZiN2QiLCJ1bmlxdWVfbmFtZSI6ImluZm9AbXllaW5zdGUuaW4iLCJuYW1laWQiOiJpbmZvQG15ZWluc3RlLmluIiwiZW1haWwiOiJpbmZvQG15ZWluc3RlLmluIiwiYXV0aF90aW1lIjoiMTIvMDQvMjAyMyAwNzo1Njo1MSIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDEzNjQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.KNhckSDK9_b7eoLjNzYEKhLczabrp4zoccyIcK04uhI";
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(templateData),
    };

    try {
      const response = await fetch(WATI_API_ENDPOINT, requestOptions);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error sending WhatsApp template message:", error);
    }
  };

  const handleSendHotMessage = () => {
    const templateData = {
      broadcast_name: "hot",
      parameters: [{ name: "hot", value: Date.now() }],
      template_name: "hot",
    };
    sendWhatsAppTemplateMessage(templateData).then((data) => {
      console.log(data);
    });
    amplitude.track(
      "Hot Confirm",
      {
        email: email,
        phone: phoneNumber,
      },
      { user_id: email }
    );
  };

  const handleSendWarmerMessage = () => {
    const templateData = {
      broadcast_name: "warmer",
      parameters: [{ name: "warmer", value: Date.now() }],
      template_name: "warmer",
    };
    sendWhatsAppTemplateMessage(templateData).then((data) => {
      console.log(data);
    });
    amplitude.track(
      "Warmer Remarket",
      {
        email: email,
        phone: phoneNumber,
      },
      { user_id: email }
    );
  };

  const retryIntervals = [
    3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 6000, 6000,
    6000, 6000, 6000, 6000, 6000, 6000, 6000, 6000, 10000, 10000, 10000, 10000,
    10000, 10000, 30000, 30000,
  ];

  const checkPaymentStatus = async () => {
    const response = await fetch(
      "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/checkPayStatus",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          merchantId: "M1TBTIGYFCW5",
          merchantTransactionId: merchantTransactionId,
        }),
      }
    );

    const data = await response.json();

    if (data.code === "PAYMENT_PENDING" && retryIntervals.length > 0) {
      handleSendWarmerMessage();
      amplitude.track(
        "Acquisition Pending",
        {
          email: email,
          phone: phoneNumber,
        },
        { user_id: email }
      );
      const nextInterval = retryIntervals.shift();
      setTimeout(checkPaymentStatus, nextInterval);
    } else if (data.code !== "PAYMENT_SUCCESS") {
      amplitude.track(
        "Acquisition Failed",
        {
          email: email,
          phone: phoneNumber,
        },
        { user_id: email }
      );
      window.location.href = "https://www.myeinste.in/cancelled";
    }

    if (data.code === "PAYMENT_SUCCESS") {
      handleSendHotMessage();
      amplitude.track(
        "Acquisition Purchase",
        {
          email: email,
          phone: phoneNumber,
          pay: "6",
        },
        { user_id: email }
      );
      window.fbq("trackCustom", "Acquisition Purchase");
      try {
        const updatePayment = async () => {
          const docRef = doc(db, "users", email);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            await updateDoc(docRef, {
              acquisition: true,
              unlock: Date.now(),
              phone: phoneNumber,
            });
          } else {
            await setDoc(docRef, {
              acquisition: true,
              unlock: Date.now(),
              phone: phoneNumber,
            });
          }

          window.location.href = "https://www.myeinste.in/congrats";
        };
        const updateRegistrant = async () => {
          const docRef = doc(db, "registrants", email);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            await updateDoc(docRef, {
              messageSent: true,
            });
          }
        };
        updateRegistrant();
        updatePayment();
      } catch (e) {
        console.error("Error updating document: ", e);
      }
    }
  };

  useEffect(() => {
    checkPaymentStatus();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center pb-40 min-h-screen  text-primaryDark w-full overflow-x-hidden max-w-screen">
      <div className="mb-10">
        <Lotties animation={animation} />
      </div>

      <div className="w-full lg:w-[90vw] 2xl:w-3/5 space-y-6 overflow-auto px-4 lg:px-10 2xl:px-20">
        <h2 className="text-xl sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl text-center font-semibold mb-4">
          We are processing your payment. Please wait.
        </h2>
      </div>
    </div>
  );
};

export default Processing;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./fonts/SF-Pro-Text-Regular.otf";
import "./fonts/SF-Pro-Text-Bold.otf";
import "./fonts/SF-Pro-Text-Semibold.otf";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);

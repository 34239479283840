// import { loadStripe } from "@stripe/stripe-js";
// import { getFunctions, httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom";
// import chevron from "./images/chevron.webp";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, firebaseApp } from "./firebase";
import { useState } from "react";
import { useEffect } from "react";

// const stripePromise = loadStripe(
//   "pk_live_51MwIA1SHvyOLXMmL8vIfXeKNF96kPqerd12UAhs2wNqFn8DbnMsCzQ9T3B1Bfo8biQSonzgZL2EN7JsOTIHNQ4lf0095622NU8"
// );

// const functions = getFunctions(firebaseApp);
// const createCheckoutSession = httpsCallable(functions, "createCheckoutSession");
// const makePayment = httpsCallable(functions, "makePayment");

// const email = "abdulkhaliqueansari@live.com";
// const merchantId = "MERCHANTUAT";
// const merchantTransactionId = "MT7850590068188104";

// const handlePay = async () => {
//   try {
//     await setDoc(doc(db, "payments", merchantTransactionId), {
//       email: email,
//       merchantTransactionId: merchantTransactionId,
//       timestamp: Date.now(),
//     });
//   } catch (e) {
//     console.error("Error adding document: ", e);
//   }
//   makePayment({
//     merchantId: "MERCHANTUAT",
//     merchantTransactionId: "MT7850590068188104",
//     merchantUserId: "MUID123",
//     amount: 10000,
//     redirectUrl: `https://www.myeinste.in/congrats?email=${email}&merchantTransactionId=${merchantTransactionId}`,
//     redirectMode: "POST",
//     callbackUrl:
//       "https://us-central1-myeinstein-f71a7.cloudfunctions.net/uiCallback",
//     mobileNumber: "9999999999",
//     paymentInstrument: { type: "PAY_PAGE" },
//   })
//     .then((result) => {
//       console.log("result", result.data);
//       if (result.data.instrumentResponse.redirectInfo.url) {
//         window.location.href = result.data.instrumentResponse.redirectInfo.url;
//       }
//     })
//     .catch((error) => {
//       // Handle any errors from createCheckoutSession
//       console.log(error);
//     });
// };

// const handlePay = async () => {
//   const stripe = await stripePromise;
//   createCheckoutSession({ priceId: "price_1NXNgESHvyOLXMmLkmprjnGG" })
//     .then((result) => {
//       // The result contains the session ID
//       const sessionId = result.data.sessionId;

//       // Now use this session ID to redirect to the Stripe checkout
//       const { error } = stripe.redirectToCheckout({
//         sessionId,
//       });

//       if (error) {
//         // Handle any errors from redirectToCheckout
//         console.log(error);
//       }
//     })
//     .catch((error) => {
//       // Handle any errors from createCheckoutSession
//       console.log(error);
//     });
// };

export const RegisterButton = () => {
  const [price, setPrice] = useState("599");

  const getPrice = async () => {
    const workshopRef = await getDoc(doc(db, "agreements", "acquisition"));

    setPrice(() => workshopRef.data().price);
  };

  useEffect(() => {
    getPrice();
  }, []);

  return (
    <Link to="/payment">
      <button className="flex w-full mx-2 flex-col bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-5 items-center justify-center">
        <text className="text-2xl">REGISTER ₹{price}/-</text>
        <text className="text-sm block mt-1">100% Refundable</text>
      </button>
    </Link>
  );
};

export const EnhancedRegisterButton = () => {
  const [price, setPrice] = useState("599");

  const getPrice = async () => {
    const workshopRef = await getDoc(doc(db, "agreements", "acquisition"));

    setPrice(() => workshopRef.data().price);
  };

  useEffect(() => {
    getPrice();
  }, []);

  return (
    <Link to="/payment">
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-lg mt-20 flex flex-col w-full items-center justify-center transition-all duration-200 ease-in-out transform hover:scale-105">
        <text className="text-2xl">REGISTER ₹{price}/-</text>
        <text className="text-sm block mt-1">100% Refundable</text>
      </button>
    </Link>
  );
};

export const Register = () => {
  const [price, setPrice] = useState("599");

  const getPrice = async () => {
    const workshopRef = await getDoc(doc(db, "agreements", "acquisition"));

    setPrice(() => workshopRef.data().price);
  };

  useEffect(() => {
    getPrice();
  }, []);

  return (
    <Link to="/payment">
      <button className="flex flex-col items-center justify-center self-start lg:self-center mt-4 mb-10 bg-yellow-500 px-8 text-white hover:bg-light  border-2 border-solid border-transparent hover:text-yellow-500 border-dark transition-all duration-300 ease-in-out p-2 rounded-lg lg:visible z-10">
        <div className="flex items-center">
          <span className="text-lg font-bold">REGISTER @ ₹{price}/-</span>
        </div>
        <div className=" text-sm font-regular">100% refundable</div>
      </button>
    </Link>
  );
};

export const SimpleRegister = () => {
  return (
    <Link to="/payment">
      <button className=" text-white px-6 py-2 rounded-lg text-md md:text-sm md:px-4 font-regular bg-red-600 ">
        Register!
      </button>
    </Link>
  );
};

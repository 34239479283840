import React, { useRef } from "react";
import { useScroll, motion, useSpring } from "framer-motion";
import LiIcon from "./LiIcon";
import brain from "../lottie/brain.json";
import data from "../lottie/data.json";
import prune from "../lottie/prune.json";
import LottieInView from "./LottieInView";
import { EnhancedRegisterButton } from "../Register";

const Details = ({ process, animation, width, work }) => {
  const ref = useRef(null);

  return (
    <li
      ref={ref}
      className="my-4 first:mt-0 last:mb-0 w-full h-auto flex md:flex-col items-center justify-between"
    >
      <LiIcon reference={ref} />
      <div className="w-full">
        <motion.div
          initial={{ y: 50 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 2, type: "spring" }}
          className="w-full h-auto self-center mt-28 md:mt-0 md:ml-12"
        >
          <h3 className="capitalize w-3/4 text-left md:text-center font-bold text-xl text-primary mb-8">
            {process}
          </h3>
          <p className="w-3/4 md:text-md text-light text-xl text-left md:text-center">
            {work}
          </p>
        </motion.div>
      </div>
      <div className="w-full">
        <LottieInView animation={animation} width={width} />
      </div>
    </li>
  );
};

const Experience = () => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const scrollY = useSpring(scrollYProgress, { damping: 10 });

  return (
    <div className="my-6 md:my-10">
      <h2 className="font-semibold !text-[3rem] lg:!text-[2rem] mb-0 md:mb-8 w-full text-center text-light">
        {"Why you need this?"}
      </h2>
      <div ref={ref} className="w-full mx-auto relative">
        <motion.div
          style={{ scaleY: scrollY }}
          className="absolute left-[49.95%]  top-[18vw] md:-left-[1px] md:top-1 lg:top-[20vw] w-[0.15rem] h-[96%] bg-primary origin-top z-1 rounded-full"
        />
        <ul className="w-full flex flex-col items-center justify-between ml-4 md:ml-0">
          <Details
            process="First 1,000 Days"
            work="85% of a baby's brain develops in the first 1,000 days of life. If you miss this super important time, it is lost forever."
            animation={brain}
          />
          <Details
            process="Synaptogenesis"
            work="In the first 3 years, more than 1,00,000 neural connections form every second. This rapid growth decides the talents they will have!"
            animation={data}
          />
          <Details
            process="Synaptic Pruning"
            work="Soon after, the brain begins to cut off unused connections. Without the right steps, baby's gained abilities could disappear forever."
            animation={prune}
            width="w-[20vw]"
          />
        </ul>
        {/* <EnhancedRegisterButton /> */}
      </div>
    </div>
  );
};

export default Experience;

import Layout from "./components/Layout";
import logoM from "./images/logoM.webp";
import dst from "./images/dst.webp";
import suo from "./images/suo.webp";
import sui from "./images/sui.webp";

const Backed = () => {
  return (
    <Layout className="flex py-4 flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center bg-blue-300 p-6">
      <h2 className="text-center mt-0 text-lg">
        A unit of Wystan{" "}
        {/* <img
          src={logoM}
          alt="Logo of Wystan Health."
          className="inline-block w-[120px] h-auto"
        />{" "} */}
        Health Pvt. Ltd.
      </h2>
      </div>

      {/* <h2 className="mt-8 text-xs">BACKED BY</h2>
      <div className="w-full flex  items-center justify-around ">
        <img
          src={dst}
          className="w-[130px] md:w-[70px] h-auto mb-12 md:mb-8 ml-1"
          alt="Logo of Department of Science and Technology."
        />

        <img
          src={suo}
          className="w-[150px] md:w-[90px] h-auto mb-10 ml-12"
          alt="Logo of Startup Odisha."
          priority
        />
        <img
          src={sui}
          className="w-[200px] md:w-[120px] h-auto mb-8"
          alt="Logo of Startup India."
          priority
        />
      </div> */}
    </Layout>
  );
};

export default Backed;

import animation from "./lottie/processing.json";
import Lotties from "./components/Lotties";
import { useEffect, useState } from "react";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import * as amplitude from "@amplitude/analytics-browser";

amplitude.init("2cfa350f9e9b60940fae5d45bee337e0");

const IgniteProcessing = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const merchantTransactionId = urlParams.get("merchantTransactionId");
  const email = urlParams.get("email");
  const phoneNumber = Number(urlParams.get("phone"));
  const price = urlParams.get("price");

  const sendWhatsAppTemplateMessage = async (templateData) => {
    const WATI_API_ENDPOINT = `https://live-mt-server.wati.io/301364/api/v1/sendTemplateMessage?whatsappNumber=${String(
      phoneNumber
    )}`;
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOWU4YTBhZS0xYjUzLTQwOWEtOWU1OS0yNmZiYmI3ZDZiN2QiLCJ1bmlxdWVfbmFtZSI6ImluZm9AbXllaW5zdGUuaW4iLCJuYW1laWQiOiJpbmZvQG15ZWluc3RlLmluIiwiZW1haWwiOiJpbmZvQG15ZWluc3RlLmluIiwiYXV0aF90aW1lIjoiMTIvMDQvMjAyMyAwNzo1Njo1MSIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDEzNjQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.KNhckSDK9_b7eoLjNzYEKhLczabrp4zoccyIcK04uhI";
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(templateData),
    };

    try {
      const response = await fetch(WATI_API_ENDPOINT, requestOptions);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error sending WhatsApp template message:", error);
    }
  };

  const createAndGenerateAWB = async (orderData) => {
    const response = await fetch(
      "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/createAndGenerateAWB",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      }
    );

    if (!response.ok) {
      window.location.href = "https://www.myeinste.in/issue";
      throw new Error("Failed to create order and generate AWB");
    }

    const data = await response.json();
    const handleSendHotMessage = () => {
      const templateData = {
        broadcast_name: "ignite",
        parameters: [
          { name: "ignite", value: Date.now() },
          { name: "iawb", value: data.awb_code },
        ],
        template_name: "ignite",
      };
      sendWhatsAppTemplateMessage(templateData).then((data) => {
        console.log(data);
      });
    };
    const updateUser = async () => {
      const docRef = doc(db, "users", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, {
          igniter: true,
          ignitionAWB: data.awb_code,
          recipes: true,
          catalog: true,
          subscription: Date.now(),
        });
      } else {
        await setDoc(docRef, {
          igniter: true,
          ignitionAWB: data.awb_code,
          recipes: true,
          catalog: true,
          subscription: Date.now(),
        });
      }
      handleSendHotMessage();
      window.location.href = `https://www.myeinste.in/confirmed?awb=${data.awb_code}`;
    };
    updateUser();
  };

  const pullFormData = async () => {
    try {
      if (email) {
        const userDoc = doc(db, "users", email);
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const formData = {
            order_date: new Date().toISOString(),
            pickup_location: "myEinstein",
            shipping_is_billing: true,
            order_items: [
              {
                name: "IGNITE",
                sku: "IGNITE",
                units: 1,
                selling_price: price,
                discount: "",
                tax: "",
                hsn: 490300,
              },
            ],
            payment_method: "Prepaid",
            sub_total: price,
            length: 37,
            breadth: 31,
            height: 8.5,
            weight: 1.6,
            billing_customer_name: data.billing_customer_name,
            billing_last_name: data.billing_last_name,
            billing_address: data.billing_address,
            billing_address_2: data.billing_address_2,
            billing_city: data.billing_city,
            billing_pincode: data.billing_pincode,
            billing_state: data.billing_state,
            billing_country: data.billing_country,
            billing_email: data.billing_email,
            billing_phone: data.billing_phone,
            order_id: merchantTransactionId,
          };
          await createAndGenerateAWB(formData);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const retryIntervals = [
    3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 6000, 6000,
    6000, 6000, 6000, 6000, 6000, 6000, 6000, 6000, 10000, 10000, 10000, 10000,
    10000, 10000, 30000, 30000,
  ];

  const checkPaymentStatus = async () => {
    const response = await fetch(
      "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/checkPayStatus",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          merchantId: "M1TBTIGYFCW5",
          merchantTransactionId: merchantTransactionId,
        }),
      }
    );

    const data = await response.json();

    if (data.code === "PAYMENT_PENDING" && retryIntervals.length > 0) {
      amplitude.track(
        "Ignition Pending",
        {
          email: email,
          phone: phoneNumber,
        },
        { user_id: email }
      );
      const nextInterval = retryIntervals.shift();
      setTimeout(checkPaymentStatus, nextInterval);
    } else if (data.code !== "PAYMENT_SUCCESS") {
      amplitude.track(
        "Ignition Failed",
        {
          email: email,
          phone: phoneNumber,
        },
        { user_id: email }
      );
      window.location.href = "https://www.myeinste.in/cancelled";
    }

    if (data.code === "PAYMENT_SUCCESS") {
      amplitude.track(
        "Ignition Purchase",
        {
          email: email,
          phone: phoneNumber,
        },
        { user_id: email }
      );
      window.fbq("trackCustom", "Ignition Purchase");
      pullFormData();
    }
  };

  useEffect(() => {
    checkPaymentStatus();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center pb-40 min-h-screen  text-primaryDark w-full overflow-x-hidden max-w-screen">
      <div className="mb-10">
        <Lotties animation={animation} />
      </div>

      <div className="w-full lg:w-[90vw] 2xl:w-3/5 space-y-6 overflow-auto px-4 lg:px-10 2xl:px-20">
        <h2 className="text-xl sm:text-xl md:text-2xl lg:text-3xl 2xl:text-4xl text-center font-semibold mb-4">
          We are processing your payment. Please wait.
        </h2>
      </div>
    </div>
  );
};

export default IgniteProcessing;

import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import payment from "./images/payment.webp";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, firebaseApp } from "./firebase";
import * as amplitude from "@amplitude/analytics-browser";
import { useNavigate } from "react-router-dom";

amplitude.init("2cfa350f9e9b60940fae5d45bee337e0");

const Payment = () => {
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(59900);
  const functions = getFunctions(firebaseApp, "asia-south1");
  const makePayments = httpsCallable(functions, "makePayments");
  const navigate = useNavigate();

  const getPrice = async () => {
    const workshopRef = await getDoc(doc(db, "agreements", "acquisition"));

    setPrice(() => Number(workshopRef.data().price) * 100);
  };

  useEffect(() => {
    return () => setIsLoading(false); // Reset on unmount
  }, []);

  useEffect(() => {
    getPrice();
    amplitude.track("Payment Page");
    window.fbq("trackCustom", "Payment Page");
  }, []);

  const createRazorpayOrder = async (amount) => {
    const createOrder = httpsCallable(functions, "createOrder");
    try {
      const response = await createOrder({ amount });
      return response.data.order;
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const loadRazorpay = (order) => {
    const options = {
      key: "rzp_test_XyDCoIZrhYoi5H",
      amount: order.amount,
      currency: order.currency,
      name: "myEinstein",
      description: "myEinstein Growth Workshop",
      image:
        "https://firebasestorage.googleapis.com/v0/b/myeinstein-f71a7.appspot.com/o/logo.jpg?alt=media&token=0f356f59-66c1-4887-be25-f53df91c1950",
      order_id: order.id,
      handler: async function (response) {
        const dataForVerification = {
          razorpay_order_id: order.id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        };
        const isVerified = await verifyRazorpayPayment(dataForVerification);
        if (isVerified) {
          console.log("Payment verification successful");
          navigate("/congrats");
        } else {
          console.error("Payment verification failed");
          navigate("/issue");
        }
      },
      prefill: {
        email: email,
        contact: phone,
      },
      theme: {
        color: "#00b3ff",
      },
      send_sms_hash: true,
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handleRazorpayPayment = async () => {
    const order = await createRazorpayOrder(price);
    console.log(order); // example amount
    loadRazorpay(order);
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const verifyRazorpayPayment = async (data) => {
    const verifyPaymentFunction = httpsCallable(functions, "verifyPayment");
    try {
      const result = await verifyPaymentFunction(data);
      return result.data.success;
    } catch (error) {
      console.error("Error verifying payment:", error);
      return false;
    }
  };

  useEffect(() => {
    loadRazorpayScript()
      .then(() => console.log("Razorpay SDK loaded"))
      .catch((error) => console.error(error.message));
  }, []);

  const addContact = async (templateData) => {
    const WATI_API_ENDPOINT = `https://live-mt-server.wati.io/301364/api/v1/addContact/whatsappNumber=${String(
      Number(phone)
    )}`;
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOWU4YTBhZS0xYjUzLTQwOWEtOWU1OS0yNmZiYmI3ZDZiN2QiLCJ1bmlxdWVfbmFtZSI6ImluZm9AbXllaW5zdGUuaW4iLCJuYW1laWQiOiJpbmZvQG15ZWluc3RlLmluIiwiZW1haWwiOiJpbmZvQG15ZWluc3RlLmluIiwiYXV0aF90aW1lIjoiMTIvMDQvMjAyMyAwNzo1Njo1MSIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDEzNjQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.KNhckSDK9_b7eoLjNzYEKhLczabrp4zoccyIcK04uhI";
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(templateData),
    };

    try {
      amplitude.track("Contact Added", {
        email: email.toLowerCase(),
        phone: phone,
      });
      const response = await fetch(WATI_API_ENDPOINT, requestOptions);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error adding contact:", error);
    }
  };

  const handleAddContact = () => {
    const templateData = {
      name: email.toLowerCase(),
      customParams: [{ name: "warm", value: Date.now() }],
    };
    addContact(templateData)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handlePay = async (e) => {
  //   e.preventDefault();
  //   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   if (!emailPattern.test(email)) {
  //     alert("Please enter a valid email address.");
  //     return;
  //   }
  //   if (!phone || phone.length > 13) {
  //     alert("Please enter a valid 10-digit phone number.");
  //     return;
  //   }
  //   handleAddContact();
  //   setIsLoading(true);
  //   const identifyObj = new amplitude.Identify();
  //   identifyObj.set("phoneNumber", phone);
  //   amplitude.identify(identifyObj, {
  //     user_id: email.toLowerCase(),
  //   });
  //   amplitude.track(
  //     "Acquisition Initiated",
  //     {
  //       email: email.toLowerCase(),
  //       phone: phone,
  //     },
  //     { user_id: email }
  //   );
  //   window.fbq("trackCustom", "Acquisition Initiated");
  //   const timestamp = Date.now();
  //   try {
  //     await setDoc(doc(db, "registrants", email.toLowerCase()), {
  //       email: email.toLowerCase(),
  //       phoneNumber: Number(phone),
  //       timestamp,
  //       messageSent: false,
  //     });
  //   } catch (e) {
  //     console.error("Error adding document: ", e);
  //   }
  //   handleRazorpayPayment();
  // };

  const handlePay = async (e) => {
    e.preventDefault();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!phone || phone.length > 13) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }
    handleAddContact();
    setIsLoading(true);
    const identifyObj = new amplitude.Identify();
    identifyObj.set("phoneNumber", phone);
    amplitude.identify(identifyObj, {
      user_id: email.toLowerCase(),
    });
    amplitude.track(
      "Acquisition Initiated",
      {
        email: email.toLowerCase(),
        phone: phone,
      },
      { user_id: email }
    );
    window.fbq("trackCustom", "Acquisition Initiated");
    const merchantTransactionId = phone.slice(5) + String(Date.now());
    const timestamp = Date.now();
    try {
      await setDoc(doc(db, "registrants", email.toLowerCase()), {
        email: email.toLowerCase(),
        phoneNumber: Number(phone),
        timestamp,
        messageSent: false,
        merchantTransactionId: merchantTransactionId,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    makePayments({
      merchantId: "M1TBTIGYFCW5",
      merchantTransactionId: merchantTransactionId,
      merchantUserId: phone,
      amount: price,
      redirectUrl: `https://www.myeinste.in/processing?email=${email.toLowerCase()}&phone=${phone}&merchantTransactionId=${merchantTransactionId}`,
      redirectMode: "POST",
      callbackUrl:
        "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/uiCallbacks",
      mobileNumber: phone,
      paymentInstrument: { type: "PAY_PAGE" },
    })
      .then((result) => {
        console.log("result", result.data);
        if (result.data.instrumentResponse.redirectInfo.url) {
          window.location.href =
            result.data.instrumentResponse.redirectInfo.url;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg max-w-md">
        <div className="text-center text-2xl font-bold mb-6">Registration</div>
        <div className="mb-3 flex flex-col items-center justify-center rounded-lg overflow-hidden shadow-lg">
          <img src={payment} alt="A mother reading to her baby." />
        </div>
        <div className="text-xs text-gray-400 italic mb-8 text-center">
          The 3-day workshop, associated activities, stories, audio and articles
          will be accessible lifetime once the cohort begins tomorrow at 5 PM.
        </div>
        <form onSubmit={handlePay}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Enter email id"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
            <div className="text-xs text-gray-400 italic mt-1">
              This email id will be required by you to login.
            </div>
          </div>
          <div className="mb-4 ">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              WhatsApp Number
            </label>
            <div className="flex">
              <PhoneInput
                placeholder="Enter phone no."
                value={phone}
                onChange={setPhone}
                limitMaxLength={true}
                defaultCountry="IN"
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "0.5rem 0.75rem",
                  fontSize: "1rem",
                  lineHeight: "1.5",
                  color: "#495057",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #ced4da",
                  borderRadius: "0.25rem",
                }}
              />
            </div>
            <div className="text-xs text-gray-400 italic mt-1">
              We will send you your FREE eBook on WhatsApp.
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-2 mt-4 rounded-md hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 active:bg-blue-700 transition duration-150 ease-in-out"
          >
            {isLoading ? "Loading..." : "Proceed to Payment"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Payment;

import React from "react";
import Layout from "./components/Layout";
import logo from "./images/logo.webp";
import hero from "./images/297.webp";
import { Register } from "./Register";
import AnimatedText from "./components/AnimatedText";

const Hero = ({ showBonus }) => {
  return (
    <div>
      <div className="absolute z-10 bottom-32 xl:bottom-16 left-24 lg:hidden lg:left-20">
        <Register />
      </div>
      <Layout
        className={" pb-0 bg-primaryDark relative pt-48 xl:pt-56 md:pt-64"}
      >
        <img
          src={logo}
          alt="Logo of myEinstein"
          className={
            "w-[200px] h-auto absolute lg:left-[50vw] lg:-translate-x-1/2 top-42 xl:top-[170px] lg:top-[160px]"
          }
        />
        <div className="flex lg:flex-col items-center justify-between w-full bg-primaryDark z-100">
          <div className="w-3/5 lg:w-full flex flex-col ">
            <AnimatedText
              className="!text-6xl lg:!text-[2.6rem] sm:!text-[2.2rem]  text-left lg:text-center w-50 !mt-0"
              text="GIVE YOUR 0-5 YEAR OLD"
            ></AnimatedText>
            <p className="my-2 text-4xl font-bold lg:text-center text-left text-green-300">
              The Brightest Future <span className="block">Possible!</span>
            </p>
            <p className="my-2 w-4/5 text-base font-regular mb-[40%] lg:font-0.5 lg:hidden text-left text-white">
              {
                "Learn from doctors, the methods to nurture your baby's intelligence."
              }
            </p>
          </div>
          <div className="w-4/5 h-auto md:w-4/5 sm:w-full lg:w-4/5 xl:w-3/5 lg:flex items-center justify-center ">
            <img
              src={hero}
              alt="A mother reading a book sitting with her baby."
              className="mb-32 lg:my-12"
            ></img>
          </div>
          <div className="hidden lg:flex flex-col items-center justify-center   ">
            <p className="my-4 text-md w-5/6 font-regular lg:font-0.5 lg:visible lg:mx-4 lg:my-2 lg:text-center text-left text-white">
              {
                "Learn from doctors, the methods to nurture your baby's intelligence."
              }
            </p>
            <Register />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Hero;

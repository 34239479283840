import animation from "./lottie/cancelled.json";
import Lotties from "./components/Lotties";

const Cancelled = () => {
  return (
    <div className="flex flex-col items-center justify-center pb-16 min-h-screen w-screen overflow-x-hidden">
      <div className="mb-10">
        <Lotties animation={animation} />
      </div>
      <div className="w-full lg:w-2/3 2xl:w-4/5 space-y-6 overflow-auto px-4 lg:px-10 2xl:px-20">
        <h2 className="text-2xl sm:text-3xl md:text-3xl lg:text-4xl 2xl:text-3xl font-semibold text-center mb-4">
          Your payment failed. Please try again.
        </h2>
      </div>
    </div>
  );
};

export default Cancelled;

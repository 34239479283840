import { useState, useEffect } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, firebaseApp } from "./firebase";
import { State, City } from "country-state-city";
import { getFunctions, httpsCallable } from "firebase/functions";
import Footer from "./Footer";
import logo from "./images/logo.webp";
import drop from "./images/drop.png";
import backgroundImage from "./images/accelerate.webp";
import * as amplitude from "@amplitude/analytics-browser";

amplitude.init("2cfa350f9e9b60940fae5d45bee337e0");

const Accelerate = () => {
  const functions = getFunctions(firebaseApp, "asia-south1");
  const makePayments = httpsCallable(functions, "makePayments");
  const [formData, setFormData] = useState({
    billing_email: "",
    billing_phone: "",
    billing_customer_name: "",
    billing_last_name: "",
    billing_address: "",
    billing_address_2: "",
    billing_country: "India",
    billing_city: "",
    billing_state: "",
    billing_pincode: "",
  });

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [cities, setCities] = useState([]);
  const [price, setPrice] = useState(5990);

  const states = State.getStatesOfCountry("IN");
  const getPrice = async () => {
    const accelerationRef = await getDoc(doc(db, "agreements", "acceleration"));

    setPrice(() => Number(accelerationRef.data().price) );
  };

  useEffect(() => {
    getPrice();
    amplitude.track("Acceleration Page");
    window.fbq("trackCustom", "Acceleration Page");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (formData.billing_email) {
        const userDoc = doc(db, "users", formData.billing_email);
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setFormData((prev) => ({
            ...prev,
            ...data,
          }));

          if (data.billing_state) {
            const stateCities = City.getCitiesOfState(
              "IN",
              states.find((state) => state.name === data.billing_state)
                ?.isoCode || ""
            );
            setCities(stateCities);
          }
        }
      }
    };

    fetchData();
  }, [formData.billing_email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "billing_pincode" && value.length > 6) {
      return;
    }
    if (name === "billing_phone" && value.length > 10) {
      return;
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "billing_state") {
      const stateCities = City.getCitiesOfState(
        "IN",
        states.find((state) => state.name === value)?.isoCode || ""
      );
      setCities(() => stateCities);
    }
  };

  useEffect(() => {
    const stateCities = City.getCitiesOfState(
      "IN",
      states.find((state) => state.name === formData.billing_state)?.isoCode ||
        ""
    );
    setCities(stateCities);
  }, [formData.billing_state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    amplitude.track(
      "Acceleration Initiated",
      {
        email: formData.billing_email,
        phone: formData.billing_phone,
        firstName: formData.billing_customer_name,
        lastName: formData.billing_last_name,
        address: formData.billing_address,
        address2: formData.billing_address_2,
        country: formData.billing_country,
        city: formData.billing_city,
        state: formData.billing_state,
        pincode: formData.billing_pincode,
      },
      { user_id: formData.billing_email }
    );
    if (!showAdditionalFields) {
      setShowAdditionalFields(true);
      return;
    }
    const userDoc = doc(db, "users", formData.billing_email);
    const merchantTransactionId =
      formData.billing_phone.slice(5) + String(Date.now());
    try {
      const timestamp = Date.now();
      await setDoc(userDoc, formData, { merge: true });
      await setDoc(doc(db, "acceleration", String(timestamp)), {
        ...formData,
        timestamp,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    makePayments({
      merchantId: "M1TBTIGYFCW5",
      merchantTransactionId: merchantTransactionId,
      merchantUserId: formData.billing_phone,
      amount: price*100,
      redirectUrl: `https://www.myeinste.in/accelerate_processing?email=${formData.billing_email}&phone=${formData.billing_phone}&merchantTransactionId=${merchantTransactionId}&price=${price}`,
      redirectMode: "POST",
      callbackUrl:
        "https://asia-south1-myeinstein-f71a7.cloudfunctions.net/uiCallbacks",
      mobileNumber: formData.billing_phone,
      paymentInstrument: { type: "PAY_PAGE" },
    })
      .then((result) => {
        console.log("result", result.data);
        if (result.data.instrumentResponse.redirectInfo.url) {
          window.location.href =
            result.data.instrumentResponse.redirectInfo.url;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <section
        className="relative text-blue-900 text-left pb-20 pt-10 flex items-center justify-left flex-col"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <img
          src={logo}
          alt="Logo of myEinstein"
          className="w-[200px] h-auto mb-4"
        />

        <div className="container mx-auto py-4 mt-2 text-center mb-16 flex flex-col items-center bg-gradient-to-r from-yellow-400 to-pink-600 shadow-xl relative z-10">
          <h1 className="text-5xl font-lobster text-white md:text-3xl font-extrabold mt-4 mb-6 tracking-wider transform scale-105">
            The Accelerate Series
          </h1>
          <img
            className="w-[180px] h-auto mb-8"
            src={drop}
            alt="Discount in price"
          />
          <div className="text-xl md:text-sm max-w-2xl text-white pt-4 pb-8 px-4 mb-6 leading-relaxed bg-opacity-20 bg-white/10 p-4 rounded-lg">
            <h2 className="font-bold underline mb-4">Only @ ₹3,970/- </h2>
            <ul className="list-decimal list-inside pl-5">
              <li className="flex items-center mb-2 mr-8">
                <span className="mr-2">
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </span>
                Accelerate Series Booster Cards
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2">
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </span>
                Booster Tags
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2">
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </span>
                The Weekly Planner Sheets
              </li>
              {/* ... Add more items as needed */}
            </ul>
          </div>
        </div>
        <div className="container mx-auto px-2 text-center">
          <form
            onSubmit={handleSubmit}
            className="max-w-lg mx-auto p-8 rounded-lg shadow-lg bg-opacity-90 bg-white text-black"
          >
            <h2 className="text-2xl font-bold mb-8">Enter Your Details</h2>

            <div className="mb-8">
              <label className="block mb-4 text-black" htmlFor="billing_email">
                Email ID
              </label>
              <input
                type="email"
                id="billing_email"
                name="billing_email"
                value={formData.billing_email}
                onChange={handleChange}
                placeholder="Enter your email ID"
                className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
                required
              />
            </div>

            <div className="mb-8">
              <label className="block mb-4 text-black" htmlFor="billing_phone">
                WhatsApp Number
              </label>
              <input
                type="number"
                id="billing_phone"
                name="billing_phone"
                value={formData.billing_phone}
                placeholder="Enter your phone number"
                onChange={handleChange}
                className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
                required
              />
            </div>

            {showAdditionalFields && (
              <>
                <div className="mb-8">
                  <label
                    className="block mb-4 text-black"
                    htmlFor="billing_customer_name"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="billing_customer_name"
                    name="billing_customer_name"
                    value={formData.billing_customer_name}
                    onChange={handleChange}
                    placeholder="Enter your first name"
                    className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
                    required
                  />
                </div>

                <div className="mb-8">
                  <label
                    className="block mb-4 text-black"
                    htmlFor="billing_last_name"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="billing_last_name"
                    name="billing_last_name"
                    value={formData.billing_last_name}
                    onChange={handleChange}
                    placeholder="Enter your last name"
                    className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
                    required
                  />
                </div>

                <div className="mb-8">
                  <label
                    className="block mb-4 text-black"
                    htmlFor="billing_address"
                  >
                    Shipping Address
                  </label>
                  <input
                    type="text"
                    id="billing_address"
                    name="billing_address"
                    value={formData.billing_address}
                    placeholder="Address Line 1"
                    onChange={handleChange}
                    className="w-full px-4 py-2 mb-6 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
                    required
                  />
                  <input
                    type="text"
                    id="billing_address_2"
                    name="billing_address_2"
                    value={formData.billing_address_2}
                    placeholder="Address Line 2"
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
                    required
                  />
                </div>

                <div className="mb-8">
                  <label
                    className="block mb-4 text-black"
                    htmlFor="billing_state"
                  >
                    State
                  </label>
                  <select
                    id="billing_state"
                    name="billing_state"
                    value={formData.billing_state}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none text-black"
                    required
                  >
                    <option value="">
                      {(formData.billing_state ||= "--Select State--")}
                    </option>
                    {states.map((state, index) => (
                      <option key={state.isoCode} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-8">
                  <label
                    className="block mb-4 text-black"
                    htmlFor="billing_city"
                  >
                    City
                  </label>
                  <select
                    id="billing_city"
                    name="billing_city"
                    value={formData.billing_city}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none text-black"
                    required
                  >
                    <option value="">
                      {(formData.billing_city ||= "--Select City--")}
                    </option>
                    {cities.map((city, index) => (
                      <option key={index} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-8">
                  <label
                    className="block mb-4 text-black"
                    htmlFor="billing_pincode"
                  >
                    Pincode
                  </label>
                  <input
                    type="number"
                    id="billing_pincode"
                    name="billing_pincode"
                    value={formData.billing_pincode}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:border-light-blue-500 focus:outline-none"
                    required
                  />
                </div>
              </>
            )}

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-bold px-4 py-2 my-3 rounded-lg shadow-lg hover:shadow-xl hover:bg-light-blue-500 hover:text-white focus:outline-none focus:ring-4 focus:ring-light-blue-500 focus:ring-opacity-50 transition-all duration-300"
            >
              {showAdditionalFields ? "Proceed to Payment" : "Continue"}
            </button>
          </form>
        </div>
      </section>
      <footer className="w-full pb-12 md:pb-0 bg-primaryDark md:mt-0 mt-2">
        <section className="w-full h-full font-medium text-lg">
          <Footer />
        </section>
      </footer>
    </>
  );
};

export default Accelerate;

import React from "react";

const Layout = ({ children, className }) => {
  return (
    <div
      className={`w-full h-full inline-block z-0 px-24 pt-16 md:p-5 lg:px-20 ${className}`}
    >
      {children}
    </div>
  );
};

export default Layout;

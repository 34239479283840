import React from "react";
import { FaCalendarCheck, FaHandshake } from "react-icons/fa";
import logo from "./images/logo.webp";

const Booked = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-green-500 flex flex-col items-center justify-center text-white">
      <img
        src={logo}
        alt="Logo of myEinstein"
        className={
          "w-[200px] h-auto absolute lg:left-[50vw] lg:-translate-x-1/2 top-10 "
        }
      />
      <div className="max-w-xl m-8 my-36 bg-white p-8 rounded-lg shadow-lg text-black">
        <h1 className="text-4xl font-bold mb-4 text-center">
          Congratulations!
        </h1>
        <p className="text-lg mb-8 text-center">
          You have successfully booked your seat for the upcoming webinar. We
          are excited to see you there!
        </p>

        <div className="border-b-2 border-gray-300 mb-8"></div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2 flex items-center">
            <FaCalendarCheck className="text-blue-500 mr-6" size={36} />
            STEP 1: Mark Calendar & Alarms!
          </h2>
          <p>
            You don't want to miss your session because you "forgot"! Take a
            minute and mark your alarms for your webinar date & time! details
            sent to your email and WhatsApp.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2 flex items-center">
            <FaHandshake className="text-green-500 mr-6 " size={36} />
            STEP 2: Commit To Show Up
          </h2>
          <p>
            You've taken the first step towards your child's success. But now
            you need to show up! Spots are limited so please make sure you don't
            take away someone else's opportunity by skipping the session!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Booked;

import React from "react";
import star from "./images/star.jpg";
import { SimpleRegister } from "./Register";

const StickyHeader = () => {
  const deadlineDate = new Date();
  deadlineDate.setDate(deadlineDate.getDate() + 1);

  const deadlineString = deadlineDate
    .toLocaleString("en-US", {
      weekday: "long",
    })
    .toUpperCase();

  return (
    <div className="fixed top-0 left-0 w-full bg-white px-16 lg:px-4 py-1 shadow-lg z-50">
      <div className="flex justify-between items-center ">
        <div className="flex flex-row items-center">
          <img
            src={star}
            alt="logo"
            className="w-10 h-10 md:w-6 md:h-6 sm:w-4 sm:h-4"
          />
          <p className="text-black italic text-lg mx-4 md:mx-0 md:text-md sm:text-sm font-bold">
            Enrollment closes this{" "}
            <span className="text-red-500">{deadlineString}</span>
          </p>
        </div>
        {/* <SimpleRegister /> */}
      </div>
    </div>
  );
};

export default StickyHeader;

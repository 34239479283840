import React from "react";
import { motion } from "framer-motion";
import { useScroll } from "framer-motion";

const LiIcon = ({ reference }) => {
  const { scrollYProgress } = useScroll({
    target: reference,
    offset: ["start end", "end center"],
  });
  return (
    <div>
      <figure className="absolute left-[50%] md:left-0 -translate-x-[37px] stroke-dark z-12">
        <svg
          className="-rotate-90"
          width="75"
          height="75"
          viewBox="0 0 100 100"
        >
          <circle
            cx="75"
            cy="50"
            r="20"
            className="stroke-0 fill-primaryDark"
          />
          <motion.circle
            style={{
              pathLength: scrollYProgress,
            }}
            cx="75"
            cy="50"
            r="20"
            className=" stroke-[0.2rem] fill-none stroke-primary"
          />
          <circle
            cx="75"
            cy="50"
            r="10"
            className="stroke-primary stroke-1 fill-primary animate-pulse"
          />
        </svg>
      </figure>
    </div>
  );
};

export default LiIcon;
